import { HttpClient } from '@store/base';
import {
  GetXSearchSuggestRequest,
  GetXSearchSuggestResponse,
  GetXSearchRankingRequest,
  GetXSearchRankingResponse,
  GetXSearchAutoCompleteRequest,
  GetXSearchAutoCompleteResponse,
} from '../../model';

/**
 * 자동완성
 */
export class GetXSearchAutoCompleteGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec({
    domainNo = 0,
    maxCount = 10,
    mode = 's',
    ...req
  }: GetXSearchAutoCompleteRequest) {
    return await this.httpClient.request<GetXSearchAutoCompleteResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_X_SEARCH_API_URL}/api/suggest`,
      body: {
        target: 'complete',
        domain_no: domainNo,
        max_count: maxCount,
        mode,
        ...req,
      },
    });
  }
}

/**
 * 추천 검색어
 */
export class GetXSearchSuggestGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec({
    domainNo = 0,
    maxCount = 10,
    ...req
  }: GetXSearchSuggestRequest) {
    return await this.httpClient.request<GetXSearchSuggestResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_X_SEARCH_API_URL}/api/suggest`,
      body: {
        target: 'related',
        domain_no: domainNo,
        max_count: maxCount,
        ...req,
      },
    });
  }
}

export class GetXSearchRankingGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec({
    domainNo = 0,
    maxCount = 10,
    ...req
  }: GetXSearchRankingRequest) {
    return await this.httpClient.request<GetXSearchRankingResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_X_SEARCH_API_URL}/api/rankings`,
      body: {
        domain_no: domainNo,
        max_count: maxCount,
        ...req,
      },
    });
  }
}
