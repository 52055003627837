import { useCallback, useRef } from 'react';
import _ from 'lodash';
import { useEffectOnce } from '@display-component/pc';

type HandlePushCallback = (args: { data: any; key: string }) => void;

type DataType = {
  event_keyword: string;
  [key: string]: any;
};

type HandleLogEventPushCallback = (args: {
  name: string;
  data: DataType;
}) => void;

type HandleRemoveCallback = (args: { key: string; value: string }) => void;

type HasCallback = (args: { key: string; value?: string }) => boolean;

declare const window: any;

const useDataLayer = (
  effect?: (args: {
    /**
     * 데이터 레이어 추가
     */
    handlePush: HandlePushCallback;

    /**
     * 데이터 레이어- 이벤트 로그 추가
     */
    handleLogEventPush: HandleLogEventPushCallback;

    /**
     * 데이터레이어 삭제
     */
    handleRemove: HandleRemoveCallback;

    /**
     * 데이터레이어 ClearObj 추가
     * (data 복제후 key 초기화 되도록 변경)
     */
    handlePushClearObj: (data: DataType) => void;

    /**
     * key or key:value 기준으로 존재하는지
     */
    has: HasCallback;
    /**
     * 데이터 레이어 추가후 바로 삭제
     */
    handlePushAndRemove: HandlePushCallback;

    /**
     * 데이터 레이어 - 이벤트 로그 추가후 바로 클리어
     */
    handleLogEventPushAndClear: HandleLogEventPushCallback;
  }) => void | (() => void),
) => {
  const remainEffect = useRef<boolean>(true);
  const has = useCallback<HasCallback>(({ key, value }) => {
    if (_.isEmpty(value))
      return window?.dataLayer?.some(
        (item: { [x: string]: string | undefined }) => _.has(item, key),
      );
    return window?.dataLayer?.some(
      (item: { [x: string]: string | undefined }) => item?.[key] === value,
    );
  }, []);

  const handlePush = useCallback<HandlePushCallback>(
    ({ data, key }) => {
      if (_.isEmpty(data[key])) return;
      if (has({ key, value: data[key] })) return;

      window?.dataLayer?.push({
        ...data,
      });
    },
    [has],
  );

  const handleLogEventPush = useCallback<HandleLogEventPushCallback>(
    ({ name, data }) => {
      window?.logEvent(name, data);
    },
    [],
  );

  const handleRemove = useCallback<HandleRemoveCallback>(
    ({ key, value }) => {
      if (!has({ key, value })) return;
      const index = window?.dataLayer?.findIndex(
        (item: { [x: string]: string | undefined }) => item?.[key] === value,
      );
      window.dataLayer.splice(index, 1);
    },
    [has],
  );

  const initializeObjectValuesToUndefined = useCallback((data: DataType) => {
    const cloneData = _.cloneDeep(data);

    for (const key in cloneData) {
      if (Object.hasOwn(cloneData, key)) {
        cloneData[key] = undefined;
      }
    }

    return cloneData;
  }, []);

  const handlePushClearObj = useCallback(
    (data: DataType) => {
      if (data) {
        const initializedData = initializeObjectValuesToUndefined(data);
        window?.dataLayer?.push({
          parameters: {
            ...initializedData,
          },
        });
      }
    },
    [initializeObjectValuesToUndefined],
  );

  const handlePushAndRemove = useCallback<HandlePushCallback>(
    ({ data, key }) => {
      handlePush({ data, key });
      handleRemove({ key, value: data[key] });
    },
    [handlePush, handleRemove],
  );

  const handleLogEventPushAndClear = useCallback<HandleLogEventPushCallback>(
    ({ name, data }) => {
      handleLogEventPush({ name, data });
      handlePushClearObj(data);
    },
    [handleLogEventPush, handlePushClearObj],
  );

  const isContainerLoad = useCallback(() => {
    return (window as any)?.google_tag_manager?.dataLayer?.gtmLoad;
  }, []);

  useEffectOnce(() => {
    const remainEffectHandler = () => {
      if (remainEffect.current) {
        remainEffect.current = false;
        return effect?.({
          handlePush,
          handleLogEventPush,
          handleRemove,
          handlePushClearObj,
          has,
          handlePushAndRemove,
          handleLogEventPushAndClear,
        });
      }
    };
    window.addEventListener('load', remainEffectHandler);
    let destroyFunc: Function | void;
    if (isContainerLoad()) {
      destroyFunc = remainEffectHandler();
    }
    return () => {
      destroyFunc?.();
      window.removeEventListener('load', remainEffectHandler);
    };
  });

  return {
    handlePush,
    handleLogEventPush,
    handleRemove,
    handlePushClearObj,
    has,
    handlePushAndRemove,
    handleLogEventPushAndClear,
  };
};

export default useDataLayer;
