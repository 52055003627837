import { useMemo } from 'react';
import useSWR from 'swr';

import { CategoryItem, GetGoodsCategoryRequest } from '@store/content';
import { ApiResponse } from '@store/base';
import { goodsGateway } from '@/di-container/goods/gateway/client';

const useGoodsCategory = () => {
  const { getRemodelingCategory } = goodsGateway();

  const fetcher = (req: GetGoodsCategoryRequest) => {
    return getRemodelingCategory.exec({ typeValue: 'PC' });
  };

  const { data: apiResponseData } = useSWR<
    ApiResponse<{
      categoryList: CategoryItem[];
    }>
  >({}, fetcher);

  const goodsCategoryList = useMemo(
    () =>
      apiResponseData?.code === 200
        ? apiResponseData?.data?.categoryList || []
        : undefined,
    [apiResponseData],
  );

  return {
    goodsCategoryList,
  };
};

export default useGoodsCategory;
