import type { ApiResponse, HttpClient } from '@store/base';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import {
  GetMemberDeliveryAddressListResponse,
  GetMemberMobileResponse,
  PostMemberDeliveryAddressListRequest,
  PutMemberDeliveryAddressListRequest,
} from '../../model';

export class GetMemberMobileGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec() {
    const apiResponse = await this.httpClient.request<GetMemberMobileResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/commons/members/mobile`,
    });

    if (apiResponse.code === 200) {
      const mobileNo = apiResponse.data?.mobileNo;
      if (mobileNo) {
        const base64 = CryptoJS.enc.Hex.parse(mobileNo).toString(
          CryptoJS.enc.Base64,
        );
        const bytes = CryptoJS.AES.decrypt(
          base64,
          CryptoJS.enc.Utf8.parse('cipher#masterkey2014$hanssem!!!!'),
          {
            iv: CryptoJS.enc.Utf8.parse(''),
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          },
        );
        const decryptedMobileNo = bytes.toString(CryptoJS.enc.Utf8);
        return {
          ...apiResponse,
          data: {
            ...apiResponse.data,
            mobileNo: decryptedMobileNo,
          },
        };
      }
    }

    return apiResponse;
  }
}

export class GetMemberDeliveryAddressListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec() {
    return await this.httpClient.request<GetMemberDeliveryAddressListResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/commons/members/delivery-address`,
    });
  }
}

export class PutMemberDeliveryAddressListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: PutMemberDeliveryAddressListRequest) {
    return await this.httpClient.request<ApiResponse<{}>>({
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/commons/members/delivery-address/${req.deliveryAddressSeq}`,
      body: _.omit(req, 'deliveryAddressSeq'),
    });
  }
}

export class PostMemberDeliveryAddressListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: PostMemberDeliveryAddressListRequest) {
    return await this.httpClient.request<ApiResponse<{}>>({
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/commons/members/delivery-address`,
      body: req,
    });
  }
}

export class DeleteMemberDeliveryAddressListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: { deliveryAddressSeq: number }) {
    return await this.httpClient.request<ApiResponse<{}>>({
      method: 'DELETE',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/commons/members/delivery-address/${req.deliveryAddressSeq}`,
      body: req,
    });
  }
}
