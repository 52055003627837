import type { HttpClient } from '@store/base';
import _ from 'lodash';
import querystring from 'query-string';
import {
  GetCategoryRequest,
  GetCategoryResponse,
  GetGoodsDetailRequest,
  GetGoodsDetailResponse,
} from '../../model/dto/goods';

export class GetGoodsDetailGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetGoodsDetailRequest) {
    return await this.httpClient.request<GetGoodsDetailResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/goods/${req.goodsId}`,
    });
  }
}

export class GetCategoryGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetCategoryRequest) {
    const query = querystring.stringify(_.omit(req, 'ctgrNo'));
    return await this.httpClient.request<GetCategoryResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/goods/${req.ctgrNo}/category-goods-ids?${query}`,
    });
  }
}
