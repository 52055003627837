import { HttpClient } from '@store/base';
import { GetConstructionCaseCheckCounselorResponse } from '../../model';

export class GetConstructCaseCheckCounselor {
  constructor(readonly httpClient: HttpClient) {}
  async exec() {
    return await this.httpClient.request<GetConstructionCaseCheckCounselorResponse>(
      {
        method: 'GET',
        url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/constructCase/checkCounselor/`,
      },
    );
  }
}
