import { useMemo } from 'react';
import useSWR from 'swr';
import { ApiResponse } from '@store/base';
import {
  GetGnbListRequest,
  GetGnbListResponseData,
  GetMenusRequest,
} from '@store/content';
import { displayGateway } from '@/di-container/display/gateway/client';

export type UseGnb = GetGnbListRequest;

const useGnb = ({ viewType, typeValue }: UseGnb) => {
  const { getGnb, getMenus } = displayGateway();

  const fetcher = (req: GetGnbListRequest) => {
    return getGnb.exec(req);
  };

  const { data: apiResponseData } = useSWR<ApiResponse<GetGnbListResponseData>>(
    { gateway: 'gnb', viewType, typeValue },
    fetcher,
  );

  const gnbList = useMemo(
    () =>
      apiResponseData?.code === 200 ? apiResponseData?.data || [] : undefined,
    [apiResponseData],
  );

  const menuFetcher = (req: GetMenusRequest) => {
    return getMenus.exec(req);
  };

  const { data: menusApiResponseData } = useSWR(
    { gateway: 'menus', viewType, typeValue },
    menuFetcher,
  );

  const menuGrpList = useMemo(() => {
    return menusApiResponseData?.code === 200
      ? menusApiResponseData?.data?.menuGrpList || []
      : undefined;
  }, [menusApiResponseData]);

  return {
    gnbList,
    menuGrpList,
  };
};

export default useGnb;
