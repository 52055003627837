import { axios } from '@/di-container/axios';
import { HttpClient } from '@store/base';
import {
  GetGnbListGateway,
  GetMenusGateway,
  GetComponentDataGateway,
  GetDisplayInfoGateway,
  GetTemplateInfoGateway,
  GetComponentListGateway,
  GetSectionMenusGateway,
  GetSectionMenusRequest,
  MenuDataItem,
  GetHamburgerMenusGateway,
} from '@store/content';
import _ from 'lodash';

export const displayGateway = () => {
  return {
    getMenus: new GetMenusGateway(axios),
    getMainSectionMenus: new ClientGetSectionMenusGateway(axios),
    getSectionMenus: new GetSectionMenusGateway(axios),
    getHamburgerMenus: new GetHamburgerMenusGateway(axios),
    getGnb: new GetGnbListGateway(axios),
    getDisplayInfo: new GetDisplayInfoGateway(axios),
    getTemplateInfo: new GetTemplateInfoGateway(axios),
    getComponentData: new GetComponentDataGateway(axios),
    getComponentList: new GetComponentListGateway(axios),
  };
};

class ClientGetSectionMenusGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetSectionMenusRequest) {
    const apiResponse = await new GetSectionMenusGateway(this.httpClient).exec(
      req,
    );

    if (apiResponse.code === 200 && apiResponse?.data?.sectionMenuList) {
      const sectionMenuList = apiResponse.data.sectionMenuList || [];

      const mallIndex = _.findIndex(sectionMenuList, { mallType: 'MALL' });
      const remodelingIndex = _.findIndex(sectionMenuList, {
        mallType: 'REMODELING',
      });

      const mallMenu: MenuDataItem[] =
        sectionMenuList[mallIndex]?.menuList || [];

      const remodelingMenu: MenuDataItem[] =
        sectionMenuList[remodelingIndex]?.menuList || [];

      return { mallMenu, remodelingMenu };
    }
  }
}
