import { HttpClient } from '@store/base';
import queryString from 'query-string';
import {
  GetSearchAddressRequest,
  GetSearchAddressResponse,
  GetSearchBlueprintsRequest,
  GetSearchBlueprintsResponse,
} from '../../model';

export class GetSearchAddressGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetSearchAddressRequest) {
    const query = queryString.stringify(req);

    return await this.httpClient.request<GetSearchAddressResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/search/address?${query}`,
    });
  }
}

export class GetSearchBlueprintsGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetSearchBlueprintsRequest) {
    return await this.httpClient.request<GetSearchBlueprintsResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/search/blueprints`,
      body: req,
    });
  }
}
