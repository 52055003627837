import type { HttpClient } from '@store/base';
import {
  GetComponentDataResponse,
  GetComponentDataRequest,
  GetComponentListRequest,
  GetComponentListResponse,
} from '../../model';

export class GetComponentDataGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetComponentDataRequest) {
    return await this.httpClient.request<GetComponentDataResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/component-data`,
      body: req,
    });
  }
}

export class GetComponentListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetComponentListRequest) {
    return await this.httpClient.request<GetComponentListResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/components`,
      body: req,
    });
  }
}
